<script setup lang="ts">
import { LoginMigration } from '@fond-of/affenzahn-ui'
import { localizeUrl } from '@fond-of/locale'

const afterLogin = () => {
  globalThis.location.href = localizeUrl('/account/orders')
}
</script>

<template>
  <LoginMigration
    source="navigation"
    @after-login="afterLogin"
  />
</template>
